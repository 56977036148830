/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    section: "section",
    div: "div",
    p: "p",
    a: "a",
    hr: "hr"
  }, _provideComponents(), props.components), {TableOfContents, HeadedStatement} = _components;
  if (!HeadedStatement) _missingMdxReference("HeadedStatement", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents), React.createElement(_components.section, {
    className: "uk-section uk-position-relative  uk-background-dark uk-light uk-position-z-index  ",
    id: ""
  }, React.createElement(_components.div, {
    className: "uk-container uk-container-medium uk-position-offset-right uk-position-relative uk-position-z-index"
  }, React.createElement(_components.div, {
    className: "uk-grid"
  }, React.createElement(_components.div, {
    className: "uk-width-expand@m"
  }, React.createElement(_components.div, {
    className: "uk-wysiwyg-default uk-text-12 uk-text-fw-l uk-padding-medium-right@m"
  }, React.createElement(_components.p, null, "Scientific principles of transparency, objectivity, and reproducibility constitute the foundation of Canopy PACT’s operations.")), React.createElement(_components.div, {
    className: "uk-wysiwyg-default uk-text-12 uk-text-fw-l uk-padding-medium-right@m"
  }, React.createElement(_components.p, null, "Adherence to these principles mandates that we  are independent from financial incentives.")), React.createElement(_components.div, {
    className: "uk-wysiwyg-default uk-text-12 uk-text-fw-l uk-padding-medium-right@m"
  }, React.createElement(_components.p, null, "Claims that Nature-based solutions have positive effects for the climate or biodiversity are based on proper consideration of three interlinked metrics:")), React.createElement("div", {
    className: "uk-three-column-in-place@m"
  }, React.createElement(HeadedStatement, {
    color: "primary",
    title: "Additionality",
    href: "https://www.nature.com/articles/s41893-024-01403-w#Sec1"
  }, React.createElement(_components.p, null, "Does the project have a provable impact which would not have happened without funding?")), React.createElement(HeadedStatement, {
    color: "quaternary",
    title: "Leakage",
    href: "https://www.science.org/doi/10.1126/science.adv8264"
  }, React.createElement(_components.p, null, "Can we account for the displacement of environmentally destructive activities to other locations?")), React.createElement(HeadedStatement, {
    color: "tertiary",
    title: "Durability",
    href: "https://www.nature.com/articles/s41558-023-01815-0"
  }, React.createElement(_components.p, null, "How long can we ensure the impacts will last for?"))), React.createElement(_components.div, {
    className: "uk-wysiwyg-default uk-text-12 uk-text-fw-l uk-padding-medium-right@m"
  }, React.createElement(_components.p, null, "With these concepts, we can quantify the number of ", React.createElement(_components.a, {
    href: "https://www.cambridge.org/engage/coe/article-details/66b9d9345101a2ffa813e37c"
  }, "Permanent Additional Carbon Tonnes/PACTs"), " attributable to nature based interventions. We can also produce evidence-based multipliers to convert credits from various standards to PACTs, and thus advise buyers on credit value and risk management.")), React.createElement("center", null, React.createElement(_components.hr), React.createElement(_components.p, {
    className: "uk-style-meta uk-color-tertiary uk-text-meta-central uk-text-13 uk-scrollspy-inview "
  }, "We have produced PACT evaluations for evaluating climate claims from all auditable REDD+ projects in tropical moist forests."), React.createElement(_components.hr)), React.createElement(_components.div, {
    className: "uk-wysiwyg-default uk-text-12 uk-text-fw-l uk-padding-medium-right@m"
  }, React.createElement(_components.p, null, "Our assessments are inherently open and built using only peer-reviewed open-source geospatial data and algorithms to make credible and independently verifiable assessments.  We work with leading scientists to rapidly integrate advances in data layers, impact assessments and consequences on the climate and nature. This ensures our assessments are up to date and anticipate future scientific developments.")))))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
